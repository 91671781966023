<script setup>

</script>
<template>
    <section class="container max-w-7xl mx-auto" style="height: 257px;margin-top: 70px">
        <div class="text-center">
          <img alt="Vue logo" src="../assets/gsw-logo.png" class="mx-auto logo">
        </div>
        <div class="text-center text-white">
            <div class="">
              <div class="" style="margin-top: 120px;">
                  <iframe width="1000" height="1145" src="slice-of-pie.html" class="mx-auto" id="iframe"></iframe>
              </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.logo {
    max-width: 800px;
}
label {
    font-weight: bold;
}
input {
    padding: 15px 20px;
    color: #000000 !important;
    border-radius: 20px;
    border: 2px solid #989898;
    max-width: 65%;
}
input:focus {
    border: 2px solid blue;
}
button {
    background-color: #989898;
    padding: 20px 60px;
    border-radius: 20px;
    font-weight: bold;
}
video {
    background-color: #000000;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}
</style>
